import {DEFAULT_PATH, userType} from './auth/accessTokenValidator';
import AccountService from './Dashboard/Services/MarketService/AccountService';
import AccountTypeService from './Dashboard/Services/MarketService/AccountTypeService';
import CalculatedSalesAmountRetailers from './Dashboard/Configurations/CalculatedSalesAmountRetailers';
import ChannelSourceService from './Dashboard/Services/MarketService/ChannelSourceService';
import CheckDigitRetailers from './Dashboard/Configurations/CheckDigitRetailers';
import CheckpointsPage from './Dashboard/PeriodRelease/CheckpointsPage';
import CompletenessPage from './Dashboard/RetailerReadiness/Completeness/CompletenessPage';
import DollarsCountOutlier from './Dashboard/Alerts/DollarsCountOutlier';
import ExtractSpotlightDrillDown from './Dashboard/Spotlight/ExtractSpotlight/ExtractSpotlightDrillDown';
import ExtractSpotlightPage from './Dashboard/Spotlight/ExtractSpotlight/ExtractSpotlightPage';
import ScaffoldingExtractsTriggerDag from './Dashboard/Scaffolding/ScaffoldingExtractsTriggerDag';
import FileExclusion from './Dashboard/Configurations/FileExclusion';
import FreshRetailers from "./Dashboard/Configurations/FreshRetailers";
import FtpConfigurationsConfigPage from './Dashboard/Configurations/FtpConfigurations/FtpConfigurationsConfigPage';
import GeographyCompositionService from './Dashboard/Services/MarketService/GeographyCompositionService';
import GeographyLevelService from './Dashboard/Services/MarketService/GeographyLevelService';
import GeographyTypeService from './Dashboard/Services/MarketService/GeographyTypeService';
import IgnoredFTPsService from './Dashboard/Configurations/IgnoredFTPsService';
import ImpliedDecimalRetailers from './Dashboard/Configurations/ImpliedDecimalRetailers';
import IngestionLogsPage from './Dashboard/IngestionLogs/IngestionLogsPage';
import LegacyAccountTypeService from './Dashboard/Services/MarketService/LegacyAccountTypeService';
import MarketServicePage from './Dashboard/Services/MarketServicePage';
import MeasureAvailabilityService from './Dashboard/Services/MarketService/MeasureAvailabilityService';
import MeasuresRelease from './Dashboard/PeriodRelease/MeasuresRelease';
import MissingDaysPage from './Dashboard/Alerts/Continuity/MissingDaysPage';
import MissingStoreAcvPage from './Dashboard/Sizing/MissingStoreAcvPage';
import MissingWeeksPage from './Dashboard/Alerts/Continuity/MissingWeeksPage';
import NormalizedReport from './Dashboard/IngestionLogs/Columns/NormalizedReport';
import OpsAlerts from './Dashboard/OpsAlerts/OpsAlerts';
import OutletsService from './Dashboard/Services/OutletsService';
import PastDuePage from './Dashboard/OpsAlerts/PastDuePage';
import PeriodReleaseChecklist from './Dashboard/PeriodRelease/PeriodReleaseChecklist';
import PrivateLabelShown from './Dashboard/PeriodRelease/PrivateLabelShown';
import ProductService from './Dashboard/Services/ProductService';
import ProjectionService from './Dashboard/Services/ProjectionService';
import PromoTriggerDag from './Dashboard/PeriodRelease/PromoTriggerDag';
import ProvidersService from './Dashboard/Configurations/ProvidersService';
import Readiness from './Dashboard/PeriodRelease/Readiness';
import RecordsCountOutlier from './Dashboard/Alerts/RecordsCountOutlier';
import ReleasabilityService from './Dashboard/Services/MarketService/ReleasabilityService';
import RetailerService from './Dashboard/Services/RetailerService';
import RetailerTotals from './Dashboard/RetailerReadiness/RetailerTotals/RetailerTotals';
import ScaffoldingPublish from './Dashboard/Scaffolding/ScaffoldingPublish/ScaffoldingPublish';
import SkipFillRetailers from './Dashboard/Configurations/SkipFillRetailers';
import SkipStores from './Dashboard/Configurations/SkipStores';
import StoreClosures from './Dashboard/Configurations/StoreClosures/StoreClosures';
import StoreService from './Dashboard/Services/StoreService';
import StoreTotals from './Dashboard/RetailerReadiness/StoreTotals/StoreTotals';
import SupplementalStoreConfig from './Dashboard/Configurations/SupplementalStoreConfig';
import System from './Dashboard/Management/System';
import TimeService from './Dashboard/Services/TimeService';
import TransactionalMultiFile from './Dashboard/Configurations/TransactionalMultiFile';
import TransactionalRetailers from './Dashboard/Configurations/TransactionalRetailers';
import UnitsCountOutlier from './Dashboard/Alerts/UnitsCountOutlier';
import UnmappedRetailersPage from './Dashboard/Unmapped/UnmappedRetailersPage';
import UnmappedStores from './Dashboard/Unmapped/UnmappedStores';
import WhatWillFill from './Dashboard/Alerts/Continuity/WhatWillFill';
import {ExtractTriggerCleanseDataCsv} from './Dashboard/Scaffolding/ExtractTriggerCleanseDataCsv';
import {MeasuresTriggerDag} from './Dashboard/PeriodRelease/MeasuresTriggerDag';
import {OpsAlertsSummary} from './Dashboard/OpsAlerts/OpsAlertsSummary';
import {ProcessingLogsPage} from './Dashboard/ProcessingLogs/ProcessingLogsPage';
import {SldReleasePage} from './Dashboard/SldRelease/SldReleasePage';
import _ from 'lodash';
import PropTypes from 'prop-types';
import IgnoredStoreService from "./Dashboard/Services/IgnoredStoreService";
import AsinService from "./Dashboard/Services/AsinService";
import PriorityRetailers from "./Dashboard/Configurations/PriorityRetailers";
import SldReadiness from "./Dashboard/SldRelease/Readiness";
import PriorityRetailersMarketInsights from "./Dashboard/Configurations/PriorityRetailersMarketInsights";
import ItemsSummaryPage from "./Dashboard/ItemsSummary/ItemsSummaryPage";
import StoreBanner from "./Dashboard/Configurations/StoreBanner";
import ReceiptLagService from "./Dashboard/Services/ReceiptLag/ReceiptLag";
import IngestionPausedRetailers from "./Dashboard/Configurations/IngestionPausedRetailers";
import CheckpointTypesService from "./Dashboard/Services/CheckpointTypesService";
import FillAllMultipleRetailers from "./Dashboard/PeriodRelease/FillAllMultipleRetailers";
import MinimumDaysOfSalesService from "./Dashboard/Services/MinimumDaysOfSalesService";
import StoreInsightsIgnoredRetailers from "./Dashboard/Configurations/StoreInsightsIgnoredRetailers";
import FillWatermarkService from "./Dashboard/Services/FillWatermarkService";
import PromoWatermarkService from "./Dashboard/Services/PromoWatermarkService";
import SkipCleanseRules from "./Dashboard/Configurations/SkipCleanseRules";
import CustomHierarchyRetailers from "./Dashboard/Services/CustomHierarchyRetailersService";
import ItemsExtract from "./Dashboard/Configurations/ItemsExtract";
import Designations from "./Dashboard/Services/Designations/Designations";
import MissingWeeksSpotlightDrillDown from "./Dashboard/Spotlight/ExtractSpotlight/MissingWeeksSpotlightDrillDown";
import CloudCostsPage from "./Dashboard/StoragePrices/CloudCostsPage";
import MissingRetailers from "./Dashboard/Management/MissingRetailers";
import RemoveMeasures from "./Dashboard/Services/RemoveMeasures/RemoveMeasures";
import DesignationDetails from "./Dashboard/Services/Designations/DesignationDetails";
import DesignationAssignStore from "./Dashboard/Services/Designations/DesignationAssignStore";
import RetailerManagementPage from "./Dashboard/Retailer/RetailerManagementPage";
import DesignationLinkChild from "./Dashboard/Services/Designations/DesignationLinkChild";
import {MissingDataRetailerExclusions} from "./Dashboard/Alerts/MissingData/pages/MissingDataRetailerExclusions";
import {MissingDataStoreExclusions} from "./Dashboard/Alerts/MissingData/pages/MissingDataStoreExclusions";
import NucleusExtractsTriggerDag from "./Dashboard/Scaffolding/NucleusExtractsTriggerDag";
import AlertIdMapping from "./Dashboard/Services/AlertIdMapping";
import CircanaDataLoad from "./Dashboard/Circana/CircanaDataLoad";
import CircanaDataQC from "./Dashboard/Circana/CircanaDataQC";
import AlertsConfigurations from "./Dashboard/Alerts/Configurations/AlertsConfigurations";
import MissingDataPage from "./Dashboard/Alerts/MissingData/components/MissingDataPage";
import FtpToDataProviderIdDecodePage from "./Dashboard/Configurations/FtpConfigurations/FtpToDataProviderIdDecodePage";
import PeriodReleaseAuditChanges from "./Dashboard/PeriodRelease/Audit/PeriodReleaseAuditChanges";
import CellsService from "./Dashboard/Services/CellsService";
import MarketStores from "./Dashboard/Services/MarketStores";
import DatamartMarkets from "./Dashboard/Services/DatamartMarkets";
import CellsToStores from "./Dashboard/Services/CellsToStores/CellsToStores";
import CellsToStoresAssignStore from "./Dashboard/Services/CellsToStores/CellsToStoresAssignStore";


const ROUTES = [
    {
        path: DEFAULT_PATH,
        pathForSearchResults: '/ingestedLogs',
        component: IngestionLogsPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser, userType.locatorsUser]
    },
    {
        path: '/accountService',
        component: AccountService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/accountTypeService',
        component: AccountTypeService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/alerts/log',
        component: OpsAlerts,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/alertsConfigurations',
        component: AlertsConfigurations,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/alerts/summary',
        component: OpsAlertsSummary,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/alertIdMapping',
        component: AlertIdMapping,
        userAccessList: []
    },
    {
        path: '/asinService',
        component: AsinService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/calculatedSalesAmountRetailers',
        component: CalculatedSalesAmountRetailers,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/channelSourceService',
        component: ChannelSourceService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/cellsService',
        component: CellsService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/cellsToStores/:cellId',
        component: CellsToStores,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/cellsToStores/assignStore/:cellId',
        component: CellsToStoresAssignStore,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/checkpoints',
        component: CheckpointsPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/checkDigitRetailers',
        component: CheckDigitRetailers,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/itemsExtract',
        component: ItemsExtract,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/freshRetailers',
        component: FreshRetailers,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/checkPointTypesService',
        component: CheckpointTypesService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/customHierarchyRetailers',
        component: CustomHierarchyRetailers,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/designations',
        component: Designations,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/designation/assignStore/:designationId',
        component: DesignationAssignStore,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/designation/:designationId',
        component: DesignationDetails,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/designation/linkChildDesignations/:designationId',
        component: DesignationLinkChild,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/marketInsights/checklist',
        component: PeriodReleaseChecklist,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/alerts/missingDays',
        component: MissingDaysPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/alerts/missingWeeks',
        component: MissingWeeksPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/alerts/missingData',
        component: MissingDataPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/continuity/whatWillFill',
        component: WhatWillFill,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/alerts/outliers/salesAmount',
        component: DollarsCountOutlier,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/extractSpotlight',
        component: ExtractSpotlightPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/receiptLag',
        component: ReceiptLagService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/extractSpotlightDrillDown',
        component: ExtractSpotlightDrillDown,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/extractTriggerCleanseDataCsv',
        component: ExtractTriggerCleanseDataCsv,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/scaffoldingExtractsTriggerDag',
        component: ScaffoldingExtractsTriggerDag,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/nucleusExtractsTriggerDag',
        component: NucleusExtractsTriggerDag,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/fileExclusion',
        component: FileExclusion,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/ftpConfigurations',
        component: FtpConfigurationsConfigPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/ftpToDataProviderIdDecode',
        component: FtpToDataProviderIdDecodePage,
        userAccessList: []
    },
    {
        path: '/geographyCompositionService',
        component: GeographyCompositionService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/geographyLevelService',
        component: GeographyLevelService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/geographyTypeService',
        component: GeographyTypeService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/ignoredFTPsService',
        component: IgnoredFTPsService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/ignoreUnmappedStoreService',
        component: IgnoredStoreService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/impliedDecimalRetailers',
        component: ImpliedDecimalRetailers,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/itemsSummary',
        component: ItemsSummaryPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/legacyAccountTypeService',
        component: LegacyAccountTypeService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/marketService',
        component: MarketServicePage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/measureAvailabilityService',
        component: MeasureAvailabilityService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/marketInsights/release',
        component: MeasuresRelease,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/marketInsights/generate',
        component: MeasuresTriggerDag,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/marketToStores',
        component: MarketStores,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/datamartToMarkets',
        component: DatamartMarkets,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/missingStoreAcv',
        component: MissingStoreAcvPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/missingDataRetailerExclusions',
        component: MissingDataRetailerExclusions,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/configurations/store/missingDataExclusions',
        component: MissingDataStoreExclusions,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/normalizedFileReport',
        component: NormalizedReport,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/outletsService',
        component: OutletsService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/alerts/pastDue',
        component: PastDuePage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/fillWatermark',
        component: FillWatermarkService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/periodReleaseAuditChanges',
        component: PeriodReleaseAuditChanges,
        userAccessList: []
    },
    {
        path: '/promoWatermark',
        component: PromoWatermarkService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/priorityRetailers/storeInsights',
        component: PriorityRetailers,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/priorityRetailers/marketInsights',
        component: PriorityRetailersMarketInsights,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/privateLabelShown',
        component: PrivateLabelShown,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/processingLogs',
        component: ProcessingLogsPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser, userType.locatorsUser]
    },
    {
        path: '/productService',
        component: ProductService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/projectionService',
        component: ProjectionService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/retailerReadiness/promo',
        component: PromoTriggerDag,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/retailerReadiness/fillAllMultipleRetailers',
        component: FillAllMultipleRetailers,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/providersService',
        component: ProvidersService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/marketInsights/readiness',
        component: Readiness,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/alerts/outliers/records',
        component: RecordsCountOutlier,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/minimumDaysOfSales',
        component: MinimumDaysOfSalesService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/releasabilityService',
        component: ReleasabilityService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/retailerReadiness/completeness',
        component: CompletenessPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/retailerReadiness/retailerTotals',
        component: RetailerTotals,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/retailerReadiness/storeTotals',
        component: StoreTotals,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/retailerService',
        component: RetailerService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/missingRetailers',
        component: MissingRetailers,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/ingestionPausedRetailers',
        component: IngestionPausedRetailers,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/retailers/:retailerId',
        component: RetailerManagementPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/scaffoldingPublish',
        component: ScaffoldingPublish,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/skipFillRetailers',
        component: SkipFillRetailers,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/skipCleanseRules',
        component: SkipCleanseRules,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/configurations/store/skip',
        component: SkipStores,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/configurations/store/banner',
        component: StoreBanner,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/storeInsights/generate',
        component: SldReleasePage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/storeInsights/readiness',
        component: SldReadiness,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/storeClosures',
        component: StoreClosures,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/storeService',
        component: StoreService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/supplementalStoreConfig',
        component: SupplementalStoreConfig,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/system',
        component: System,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/timeService',
        component: TimeService,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/transactionalMultiFile',
        component: TransactionalMultiFile,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/transactionalRetailers',
        component: TransactionalRetailers,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/alerts/outliers/salesUnits',
        component: UnitsCountOutlier,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/unmappedRetailers',
        component: UnmappedRetailersPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/unmappedStores',
        component: UnmappedStores,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/storeInsightsIgnoredRetailers',
        component: StoreInsightsIgnoredRetailers,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/missingWeeksSpotlightDrillDown',
        component: MissingWeeksSpotlightDrillDown,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/cloudCosts',
        component: CloudCostsPage,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/removeMeasures',
        component: RemoveMeasures,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/conventionalDataUnload',
        component: CircanaDataLoad,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    },
    {
        path: '/conventionalDataQC',
        component: CircanaDataQC,
        userAccessList: [userType.dataGovernance, userType.dataOperationsUser]
    }
];

export const routesPropTypes = PropTypes.arrayOf(
    PropTypes.shape({
        path: PropTypes.string.isRequired,
        pathForSearchResults: PropTypes.string,
        component: PropTypes.elementType.isRequired
    })
).isRequired;

export default _.cloneDeep(ROUTES);